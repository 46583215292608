<template>
  <div class="user-page grey lighten-3" :class="{'mobile': $vuetify.breakpoint.smAndDown}">
    <main-nav v-if="$vuetify.breakpoint.mdAndUp"></main-nav>

    <mobile-nav v-if="$vuetify.breakpoint.smAndDown"></mobile-nav>

    <div class="fill-height" :class="{'d-flex flex-column align-center justify-start': !isLoaded}">
      <!-- loading -->
      <div class="py-3 d-flex align-center justify-start">
        <template v-if="videos.length > 0 && isLoaded">
          <v-avatar class="mr-2 my-1 pl-xs-2" :style="Object.hasOwnProperty.call(videos[0],'bg') ? `background-color:${videos[0].bg}` : 'white'">
            <v-img :src="videos[0].avatar"></v-img>
          </v-avatar>
          <strong class="title">{{videos[0].displayName}}'s Youtorials</strong>
        </template>
        <template v-else>This User hasn't created any videos yet</template>

      </div>
      <template v-if="!isLoaded">
        <v-card class="col-xs-10 col-md-4 elevation-0" color="transparent">
          <v-card-title class="grey--text">
            Loading Youtorials
          </v-card-title>
          <v-card-text>
            <v-progress-linear indeterminate color="primary"></v-progress-linear>
          </v-card-text>
        </v-card>
      </template>

      <!-- videos -->
      <!-- <video-cards :videos="videos" :withEdit="true" v-if="videos.length > 0 && isLoaded"></video-cards> -->
      <template v-if="videos.length > 0 && isLoaded" class="videoList">
        <div class="videoListItem d-flex" v-for="(video, index) in videos" :key="index">
          <v-img
          style="cursor:pointer; max-width:150px; min-width:120px"
          class="white--text align-end flex-auto"
          :src="video.thumbnail_medium"
          @click="$router.push({name:'watch', params: {videoId: video['.key']}})"

          ></v-img>
          <div class="col-12 col-md-5">
            <div class="subtitle">{{video.title}}</div>
            <strong class="primary--text">{{video.channelTitle}}</strong>
          </div>
          <!-- <div class="col-12 col-md-4">

          </div> -->
          <div class="flex-auto d-flex align-center justify-space-between">
            <v-spacer></v-spacer>
            <v-btn color="primary darken-1" text @click="$router.push({name:'watch', params: {videoId: video['.key']}})">
              <v-icon>play_arrow</v-icon> Play
            </v-btn>
          </div>
        </div>
      </template>

      <!-- no videos -->

      <template v-else-if="videos.length == 0 && isLoaded">
        <div class="container">

          <v-card class="col-sm-10 col-12 col-md-4 elevation-0" color="grey lighten-2" >
            <v-card-title>
              Hmmm, nothing here...
            </v-card-title>
            <v-card-text>
              That's ok, they will prolly make one someday.
            </v-card-text>
            <v-card-actions class="align-center justify-center">
              <v-btn class="gradientBG" color="" block dark large @click="$router.push({name:'build'})">
                <v-icon class="mr-2">video_library</v-icon>Create</v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </template>


    </div>

  </div>
</template>

<script>
const mainNav = () => import("@/components/shared/nav.vue");
const mobileNav = () => import("@/components/shared/mobile-nav.vue");
const videoCards = () => import("@/components/shared/video-cards.vue");
import { db } from '@/firebase';
import { mapGetters } from 'vuex'
export default {
  name: "userPage",
  components:{
    mainNav, mobileNav, videoCards,
  },
  data(){
    return{
      videos: [],
      isLoaded: false,
    }
  },
  methods:{
    getVideos(){
      let vThis = this;
      vThis.$binding("videos", db.collection('videos').where('userId','==', this.$route.params.userId))
      .then((res) => {
        vThis.isLoaded = true;
      }).catch(err => {
        console.error(err)
      })
    },
    setVideo(video){
      let vThis = this;
      this.$store.dispatch('setVideo', video).then(data=>{
        vThis.$router.push({name:'watch', params: {videoId: video['.key']}})
      })
    }
  },
  mounted(){
    this.getVideos();

    if(this.$vuetify.breakpoint.mdAndUp){
      this.$store.dispatch('toggleDrawer', true);
    }
  }
  // firestore() {
  //   return {
  //     videos: db.collection('videos').where('userId','==', this.user)
  //   }
  // },
};
</script>


<style lang="scss">
.user-page{
  position: relative;
  height: 100vh;
  padding-top: 70px;
  padding-left: 280px;
  .videoListItem{
    border-bottom: 1px solid #ccc;
    margin: 0 0 5px 0;
    padding-bottom: 5px;
  }
  &.mobile{
    padding-top:0;
    padding-left: 0;
    .container{
      padding: 0;
      .row {
        margin: 0;
        > div{
          padding: 0;
        }
      }
      .v-card{
        padding-bottom: 12px;
      }
    }
  }
}
</style>
